import React, { Component } from 'react';
import axios from '../../helper/Axios';
import { NotificationManager } from 'react-notifications';
import infeedo_logo from '../../../static/images/infeedo.svg';
import info from '../../../static/images/info-icon-2.svg'
import DataUploadModal from './DataUploadModal';
import Loader from '../loader/Loader';
import { navigate } from "gatsby";
import ReactTooltip from 'react-tooltip';
import envelope from "../../../static/images/envelope.png";
import {jwtVerifyAndDecode} from '../../helper/verifyToken';
import config from '../../../config';
import classnames from 'classnames';
import handleException from '../../helper/errorHandling';
import LanguageOverrideModal from './LanguageOverrideModal';
export default class DatabaseUploadSecure extends Component {

    state = {
        is_active: false,
        upload_type: 1,
        enabled_users_count: 0,
        loading: false,
        loading_modal: false,
        csv_valid: false,
        csv_cleaned: false,
        files: [],
        file_link: '',
        file_key: '',
        error_msg: '',
        conflict_users: 0,
        conflict_users_file_key: '',
        show_language_conflict_modal: false,
        change_log: {
            number_of_creation: 0,
            number_of_disabled: 0,
            number_of_updation: 0,
            number_of_user_upload: 0
        },
        empty_column_warning: [],
        partial_columns_warning: [],
        personal_email_warnings: 0,
        roll_out_flag: false,
        disable_master_upload: false,
        current_client_id: 0,
        client_details: {},
        clean_file_download_text: 'Download Clean File'
    }

    componentDidMount() {
        jwtVerifyAndDecode((sessionStorage.token)).then((_decoded_token) => {
            this.getEnabledUsersCount();
            this.checkForRollOutClients(_decoded_token);
        }).catch((error) => {
            navigate("/database-upload")
        })
    }

    checkForRollOutClients = (decoded_token) => {
        const current_client_id = decoded_token.client_id;
        if (config.rollout_clients.includes(current_client_id)) {
            this.setState({ roll_out_flag: true });
        } else {
            this.setState({ roll_out_flag: false });
        }
        this.setState({ current_client_id }, this.getAdminData())
    }

    getAdminData = () => async () => {
        try {
            const response = await axios.get('/v1/admin?is_accessible=true');
            if (response.data) {
                this.setState({ client_details: response.data })
            } else {
                NotificationManager.info('Failed to fetch admin data')
            }
        } catch (error) {

        }
    }

    closeModal = () => {
        this.resetUploadVariables(true);
        this.setState({ is_active: false });
    }

    setUploadType = (upload_type) => {
        this.setState({ upload_type }, () => this.openFileSelector());
    }

    openModal = () => {
        this.setState({ is_active: true });
    }

    cleanUserCSV = async () => {
        const { upload_type, files, roll_out_flag } = this.state;
        let { file_key } = this.state;
        let { change_log, empty_column_warning, partial_columns_warning, personal_email_warnings } = this.state;
        if (!files.length) {
            return;
        }
        const formData = new FormData();
        formData.append('users', files[0]);
        formData.append('upload_type', upload_type);
        this.setState({ loading_modal: true });
        try {
            if (!roll_out_flag) {
                const response = await axios.post('/v1/users/clean_users?is_accessible=true', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
                if (response && response.status === 200) {
                    if (response.data.success) {
                        change_log.number_of_creation = response.data.number_of_creation;
                        change_log.number_of_disabled = response.data.number_of_disabled;
                        change_log.number_of_updation = response.data.number_of_updation;
                        change_log.number_of_user_upload = response.data.number_of_user_upload;
                        empty_column_warning = (response.data.warnings.empty_column_warning.length ? response.data.warnings.empty_column_warning : []);
                        partial_columns_warning = (response.data.warnings.partial_columns_warning.length ? response.data.warnings.partial_columns_warning : []);
                        personal_email_warnings = (response.data.warnings.personal_email_warnings ? response.data.warnings.personal_email_warnings : 0);
                        file_key = response.data.file_key;
                        this.setState({
                            csv_valid: true,
                            change_log,
                            empty_column_warning,
                            partial_columns_warning,
                            personal_email_warnings,
                            file_key
                        });
                        const conflict_users = response.data.conflict_users;
                        if(conflict_users && conflict_users !== 0){
                            this.setState({
                                show_language_conflict_modal: true,
                                is_active: false,
                                conflict_users: conflict_users,
                                conflict_users_file_key: response.data.conflict_users_file_key
                            })
                        }
                        else{
                            this.setState({
                                is_active: true
                            })
                        }
                    } 
                    else {
                        let download_button_text = response.data.type_of_errors === 'Max Limit Exceeded' ? 'Download New Employee List' : 'Download Clean File'
                        this.setState({ csv_valid: false, error_msg: response.data.error_msg, clean_file_download_text: download_button_text });
                    }
                    this.setState({ loading_modal: false, file_link: (response.data.file_link ? response.data.file_link : ''), csv_cleaned: true });
                }
            } else {
                this.openModal();
                formData.delete('upload_type');
                formData.delete('primary_key');
                const response = await axios.post('/v1/users/clean_csv?is_accessible=true', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
                if (response && response.status === 200) {
                    NotificationManager.success('Data Updated Successfully. All changes should be visible within the next 24 hours.');
                    this.setState({ loading_modal: false });
                    this.closeModal();
                }
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    NotificationManager.error("Link Expired");
                    this.goToHome();
                } else if (error.response.status === 400 && roll_out_flag) {
                    this.setState({ csv_valid: false, error_msg: error.response.data.error_msg, loading_modal: false, file_link: (error.response.data.file_link ? error.response.data.file_link : ''), csv_cleaned: true });
                } else {
                    handleException(error, 'Reset Failed');
                }
            } else {
                handleException(error);
            }
            if (!roll_out_flag) {
                this.resetUploadVariables();
            }
        }
    }

    resetUploadVariables = (called_from_close = false) => {
        const { roll_out_flag } = this.state;
        this.setState({
            upload_type: this.state.client_details.employee_unique_key,
            loading: false,
            loading_modal: false,
            csv_valid: false,
            csv_cleaned: false,
            files: [],
            file_link: '',
            error_msg: '',
            change_log: {
                number_of_creation: 0,
                number_of_disabled: 0,
                number_of_updation: 0,
                number_of_user_upload: 0
            },
            empty_column_warning: [],
            partial_columns_warning: [],
            personal_email_warnings: 0,
            is_active: false,
            file_key: ''
        });
        if (roll_out_flag && !called_from_close) {
            this.openFileSelector()
        }
    }

    goToHome = () => {
        sessionStorage.clear();
        setTimeout(() => { navigate("/") }, 2000);
    }

    getEnabledUsersCount = async () => {
        this.setState({ loading: true });
        try {
            const response = await axios.get('/v1/get_enabled_users_count?is_accessible=true');
            if (response.status === 200) {
                this.setState({ enabled_users_count: response.data.count, loading: false });
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    NotificationManager.error("Link Expired");
                    this.goToHome();
                } else {
                    handleException(error, 'Reset Failed');
                }
            } else {
               handleException(error);
            }
        }
    }

    uploadUserCSV = async () => {
        const { upload_type, file_key } = this.state;
        this.setState({ loading_modal: true });
        try {
            const response = await axios.post('/v2/users/upload?is_accessible=true', {file_key, upload_type});
            if (response && response.status === 200) {
                NotificationManager.success('Data Updated Successfully. All changes should be visible within the next 24 hours.');
                this.setState({ loading_modal: false });
                this.closeModal();
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    NotificationManager.error("Link Expired");
                    this.goToHome();
                } else {
                    handleException(error, 'Reset Failed');
                }
            } else {
                handleException(error);
            }
        }
    }

    uploadCSV = (e) => {
        if (e.target.files) {
            this.setState({ files: e.target.files }, () => { this.openModal(); this.cleanUserCSV() });
        }
    }

    openFileSelector = () => {
        document.getElementById('uploadUsers').click()
    }

    overridePreferredLanguages = async ( file_key, override_preferred_language) => {
        const {upload_type} = this.state;
        try {
            let response = {}
            response = await axios.post('/v1/users/override_users_language?is_accessible=true',{
                file_key,
                override_preferred_language,
                upload_type
            });
    
            if(response && response.status === 200){
                return response;
            }
    
    
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    NotificationManager.error("Link Expired");
                } else {
                    handleException(error, 'Reset Failed');
                }
            } else {
                handleException(error);
            }
        }
    }

    onLanguageConflictDeny = async () => {
        const {file_key} = this.state;
        this.setState({
            loading: true
        });
        try {
            let response = await this.overridePreferredLanguages(file_key,false);
            if(response){
                this.setState({
                    show_language_conflict_modal: false,
                    is_active: true,
                    loading: false,
                });
            }
        } catch (error) {
            handleException(error);
        }
    }

    onLanguageConflictConfirm = async () => {
        const {file_key} = this.state;
        this.setState({
            loading: true
        });
        try {
            let response = await this.overridePreferredLanguages(file_key,true);
            if(response){
                this.setState({
                    show_language_conflict_modal: false,
                    is_active: true,
                    loading: false,
                });
            }
        } catch (error) {
            handleException(error);
        }
    }

    render() {
        const { is_active, upload_type, loading, enabled_users_count, loading_modal, csv_cleaned, error_msg, file_link, change_log, empty_column_warning, partial_columns_warning, personal_email_warnings, roll_out_flag, client_details, clean_file_download_text, conflict_users, conflict_users_file_key, show_language_conflict_modal } = this.state;
        return (
            <div className="column is-8 mar-auto pd-25 upload-section">

                <figure className="app-logo">
                    <img src={infeedo_logo} width="130px" alt="amber logo" />
                </figure>
                <div className="columns is-mobile">
                    <div className="column no-pd-bottom"><h1 className="login-heading no-margin-padding-bottom font-montserrat">Database Upload</h1></div>
                </div>
                <p className="font-14">Using this feature you can add, edit and delete users fom Amber's database</p>
                <br />
                <article className="message is-link">
                    <div className="message-body has-text-grey-darker">
                        <h2 className="has-text-weight-bold mg-bottom-10"><u>Guidelines to Upload File</u></h2>
                        <div className="content">
                            <ul className="line-height-li-22">
                                <li><b>Mandatory columns</b> include Full Name, Email ID, Preset Language (leave cells blank if language support is disabled), DoJ, Department, Location, City, Country, {client_details.is_employee_code_unique_key ? 'Employee Code,' : ''} Cohort (if multiple cohorts exist). <br /><b>Please note:</b> In case you’ve left any column blank, the same will reflect on the dashboard as blank]</li>
                                <li>
                                    <strong className="marginbottom-xs">About Preset Language column:</strong>
                                    <ul>
                                        <li>
                                            Amber now supports 4 languages: English, Malay, Indonesian, and French. This column helps Amber know employee’s preset language and she converses using the same.
					                    </li>
                                        <li>
                                            If language support is <strong>disabled</strong> for your account, then leave cells blank. Amber will continue reaching out in English. Note: By default, language support is disabled for your account.
					                    </li>
                                        <li>
                                            If language support is <strong>enabled</strong> for your account, then enter preset language value for each employee as English, Malay, Indonesian, or French
					                    </li>
                                        <li>
                                            If language support was ever enabled in the past (and is now disabled), enter preset language value for each employee as English
                                        </li>
                                        <li>
                                            If you want to enable/disable language support then reach out to your customer success manager or contact us at <a href="mailto:help@infeedo.com">help@infeedo.com</a>
                                        </li>
                                    </ul>
                                </li>
                                <li><b>Positioning of columns</b> should be as it is in the sample file when you upload the sheet. System will not accept any file with altered default column position.</li>
                                <li><b>Do not remove any column</b> from file if not related to your organization.</li>
                                <li><b>Date formats</b> must be the same as sample file.</li>
                                <li>Ensure there are <b>no duplicate email ID and employee code.</b></li>
                                <li>Make sure Primary Admin/Admin/Sub-admin or the user who is uploading the data, is included in the file while uploading the master file to avoid being marked as ex-employee.</li>
                                <li>Ensure <b>DOJs are correct</b> to avoid Amber reaching out to employees on incorrect occasions.</li>
                                <li>Mention <b>Leaving Date</b> to mark an Employee as <b>Ex-Employee.</b></li>
                            </ul>
                        </div>
                    </div>
                </article>
                {loading ?
                    <div className="has-text-centered"><Loader /></div>
                    : <div className="columns flex-wrap has-text-centered pd-10">
                        {enabled_users_count > 12000 || roll_out_flag ? null
                            : <div className={classnames('column is-paddingless', { 'is-disabled': !client_details.master_dataupload_enabled })}>
                                <div className="db-button" onClick={this.setUploadType.bind(this, 2)}>
                                    <h3 className="has-text-weight-bold mg-bottom-5">
                                        Master Data Upload
                                &nbsp;<span data-tip="This is used for cases when admin wants <br/>to change the entire database and <br/>disable the previous existing data."><img src={info} width="15px" alt="info" className="info-icon" /></span>
                                    </h3>
                                    <p><small>Replace the existing data: arrange data in the right format, upload it here in batches of 12,000 each.</small></p>
                                </div>
                            </div>}
                        {roll_out_flag ? null : <div className="column is-paddingless">
                            <div className="db-button" onClick={this.setUploadType.bind(this, 1)}>
                                <h3 className="has-text-weight-bold mg-bottom-5">
                                    Add/Update/Disable Employees
                                &nbsp;<span data-tip="This is used for cases when admin wants <br/>to update the existing data by updating some<br/> fields for employees or add/disable employees"><img src={info} width="15px" alt="info" className="info-icon" /></span>
                                </h3>
                                <p><small>Update the existing data: arrange data in the right format, upload it here in batches of 12,000 each.</small></p>
                            </div>
                        </div>}
                        {roll_out_flag ?
                            <div className="column is-paddingless">
                                <div className="db-button" onClick={this.openFileSelector.bind(this, 1)}>
                                    <h3 className="has-text-weight-bold mg-bottom-5">
                                        Upload Database
                                &nbsp;<span data-tip="This is used for cases when admin wants <br/>to update the existing data by updating some<br/> fields for employees or add/disable employees"><img src={info} width="15px" alt="info" className="info-icon" /></span>
                                    </h3>
                                    <p><small>Arrange data in the right format, upload it here in batches of 12,000 each.</small></p>
                                </div>
                            </div>
                            : null}
                        <div className="column is-paddingless is-12">
                            <div className="db-button">
                                {roll_out_flag ? <a target="_blank" rel="noopener noreferrer" href="./assets/Sample_DB_Upload_v2.csv" className="anchor-hov-blue">
                                    <h3 className="has-text-weight-bold mg-bottom-5">
                                        <span className='button-tag'>New</span>
                                        Download Sample Data File
                                    </h3>
                                    <p><small>See the sample format our system understands while uploading the list of employees.</small></p>
                                    <p className='has-text-danger'><small>Please use the new sample file as the format has changed.</small></p>
                                </a>
                                    : <a target="_blank" rel="noopener noreferrer" href="./assets/Sample_DB_Upload_v2.xlsx" className="anchor-hov-blue">
                                        <h3 className="has-text-weight-bold mg-bottom-5">Download Sample Data File
                                        <span className='button-tag'>New</span>
                                        </h3>
                                        <p><small>See the sample format our system understands while uploading the list of employees.</small></p>
                                        <p className='has-text-danger'><small>Please use the new sample file as the format has changed.</small></p>
                                    </a>}
                            </div>
                        </div>
                        <ReactTooltip place="top" effect="solid" html={true} class='bg-blue-up' />
                    </div>}
                <br />
                <div>
                    <p className="has-text-centered anchor-blue is-size-6">
                        <img src={envelope} width="22px" className="email-icon" alt="email" />
                        <a href="mailto:help@infedo.com" target="_blank" rel="noopener noreferrer" className="anchor-blue-only">Need help?</a>
                    </p>
                </div>
                <DataUploadModal
                    is_active={is_active}
                    onClose={this.closeModal}
                    upload_type={upload_type}
                    selectIdentifier={this.selectIdentifier}
                    uploadCSV={this.uploadCSV}
                    FinaluploadUserCSV={this.uploadUserCSV}
                    openFileSelector={this.openFileSelector}
                    loading={loading_modal}
                    csv_cleaned={csv_cleaned}
                    error_msg={error_msg}
                    resetUploadVariables={this.resetUploadVariables}
                    file_link={file_link}
                    change_log={change_log}
                    empty_column_warning={empty_column_warning}
                    partial_columns_warning={partial_columns_warning}
                    personal_email_warnings={personal_email_warnings}
                    clean_file_download_text={clean_file_download_text}
                />
                <LanguageOverrideModal
                is_active={show_language_conflict_modal}
                loading={loading}
                no_of_conficts={conflict_users}
                file_key={conflict_users_file_key}
                onConfirm={this.onLanguageConflictConfirm}
                onDeny={this.onLanguageConflictDeny}
                />
            </div>
        )
    }
}